<template>
  <section :class="this.$vuetify.theme.dark ? '' : 'grey lighten-4'">
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
      </v-col>
    </v-row>
    <v-container class="px-5">
      <v-btn color="info" @click="$router.go(-1)" class="my-3"
        ><v-icon small class="mr-1">mdi-arrow-left</v-icon> ย้อนกลับ</v-btn
      >
      <v-row>
        <v-col cols="12" xl="10" lg="9" md="8" sm="8" class="py-5">
          <v-container>
            <v-row class="pa-3" cols="12">
              <v-card-text>
                <h1 class="ml-8 mb-3">
                  รายการบทเรียน {{ heroAlt[0].heading }}
                </h1>

                <v-timeline align-top dense>
                  <v-timeline-item
                    large
                    v-for="(lesson, index) in lessons"
                    :key="lesson.lessonId"
                    :color="getTimelineColor(lesson.isDone, index)"
                  >
                    <div>
                      <div class="font-weight-normal">
                        <v-img
                          class="rounded-lg mb-1"
                          :src="imgPath(lesson.lessonImage, 'lesson')"
                          max-width="350"
                          max-height="350"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                      <h1 class="mt-2 mb-2">
                        <strong>{{ lesson.lessonName }}</strong>
                        <v-chip class="ml-2" small color="blue lighten-4"
                          >จำนวนวิดีโอ {{ lesson.videoAmount }}</v-chip
                        >
                      </h1>
                      <v-btn
                        color="primary"
                        class="mt-2"
                        :disabled="getButtonSta(lesson.isDone, index)"
                        @click="toViewCourse(lesson)"
                        >เลือกเรียน</v-btn
                      >

                      <v-btn
                        color="info"
                        class="mt-2 ml-2"
                        :disabled="getButtonSta(lesson.isDone, index)"
                        @click="toDoQuiz(lesson)"
                        >ทำแบบฝึกหัด</v-btn
                      >
                      <v-divider class="mt-2"></v-divider>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-row>
          </v-container>

          <v-row class="pa-3" cols="12">
            <v-card-text>
              <h1 class="ml-8 mb-3">
                แบบทดสอบ
                <v-btn
                  class="ml-2"
                  color="primary"
                  :disabled="testButton"
                  @click="toDoTest(lesson)"
                  >ทำแบบทดสอบ</v-btn
                >
              </h1>

              <div>
                <div class="font-weight-normal"></div>
                <v-divider class="mt-2"></v-divider>
              </div>
            </v-card-text>
          </v-row>

          <!-- <div class="text-center" v-if="!isError">
            <h1 class="mt-3">ยังไม่มีบทเรียนในหลักสูตรนี้...</h1>
          </div> -->

          <div class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination
                      v-model="page"
                      circle
                      class="my-4"
                      :length="1"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <!-- <v-col cols="12" xl="2" lg="3" md="4" sm="4" class="py-16">
          <aside>
            <v-text-field
              clearable
              dense
              flat
              outlined
              placeholder="Search..."
              append-icon="mdi-magnify"
              class="mb-6"
              hide-details
            >
            </v-text-field>
            <v-card outlined class="mb-6">
              <div
                class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                บทเรียน
              </div>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="lesson in lessons"
                    :key="lesson.lessonId"
                    @click="toViewCourse(lesson)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="lesson.lessonName"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        v-text="lessonAmount(lesson.videoAmount)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </aside>
        </v-col> -->
      </v-row>
    </v-container>
  </section>
</template>

<script>
import "@/mixins/generalMixin";
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "MyLesson",
  components: {
    HeroAlt,
  },
  data() {
    return {
      testButton: false,
      power: 0,
      isError: false,
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "",
        },
      ],
      lessons: [],
      stdData: [],
      posts: [
        {
          id: "",
          title: "Hello World",
          postImage: "",
          publishedOn: "",
          lastUpdated: "",
          author: "",
          tags: "",
          category: "",
          excerpt: "",
          content: "",
        },
      ],
      page: 1,
    };
  },
  methods: {
    getTimelineColor(isDone, index) {
      if (isDone === 1) {
        return "green";
      }

      if (this.lessons[index - 1]) {
        if (
          this.lessons[index - 1].isDone === 1 &&
          this.lessons[index].isDone === 0
        ) {
          return "amber";
        }
      }

      if (isDone === 0) {
        return "grey";
      }
    },

    getButtonSta(isDone, index) {
      if (index === 0) {
        return false;
      }

      if (index > 0) {
        if (this.lessons[index - 1].isDone === 1) {
          return false;
        }
      }

      if (isDone === 0) {
        return true;
      }
    },

    toDoQuiz(lesson) {
      localStorage.setItem("lessonId", lesson.lessonId);
      localStorage.setItem("subjectId", lesson.subjectId);
      localStorage.setItem("lessonName", lesson.lessonName);
      this.$router.push({
        name: "DoQuiz",
      });
    },

    toDoTest() {
      localStorage.setItem("subjectId", this.lessons[0].subjectId);
      this.$router.push({
        name: "DoTest",
      });
    },

    lessonAmount(videoAmount) {
      return `จำนวนวิดีโอ ${videoAmount}`;
    },

    toViewCourse(lesson) {
      this.$router.push({
        name: "ViewCourse",
        query: {
          lessonId: lesson.lessonId,
        },
      });
    },

    getMyCourse() {
      const jsonData = JSON.stringify({
        subjectId: localStorage.getItem("subjectId"),
        stdId: localStorage.getItem("stdId"),
      });

      this.$http
        .post(
          `${process.env.VUE_APP_API_PATH}/lesson/getLessonBySubject.php`,
          jsonData
        )
        .then((res) => {
          // console.log(res.data);
          this.lessons = res.data["lessonData"];
          this.stdData = res.data["stdData"];

          this.lessons.map((lesson, index) => {
            this.stdData.map((data) => {
              if (lesson.lessonId === data.lessonId) {
                this.lessons[index].isDone = data.isDone;
                this.lessons[index].progess = data.progress;
                this.lessons[index].logId = data.logId;
                this.lessons[index].finishDate = data.finishDate;
                this.lessons[index].startDate = data.startDate;
                this.lessons[index].subjectId = data.subjectId;
                this.lessons[index].lessonId = data.lessonId;
                this.lessons[index].subjectId = data.subjectId;
              }
            });
          });

          this.lessons.map((lesson) => {
            lesson.isDone === 1
              ? (this.testButton = false)
              : (this.testButton = true);
          });

          console.log(this.testButton);

          if (!this.testButton) {
            localStorage.setItem("isTest", true);
          } else {
            localStorage.setItem("isTest", false);
          }

          this.heroAlt[0].heading = this.lessons[0].subjectName;
          console.log("lessons", this.lessons);
          console.log("stdData", this.stdData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getMyCourse();
  },
};
</script>
