<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group v-for="(item, i) in items" :key="i" color="primary">
          <v-list-item v-if="!item.submenu" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title.toUpperCase()" />
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title.toUpperCase()"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.submenu"
              :key="child.title"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>

        <v-list dense>
          <v-row justify="center my-2">
            <v-btn
              outlined
              color="primary"
              @click="toMyCourse"
              v-if="isUser && isMobile"
            >
              คอร์สของฉัน
            </v-btn>
          </v-row>

          <!-- <v-row justify="center my-2">
            <v-btn
              dark
              class="mr-2"
              color="green"
              to="/register"
              v-if="!isUser && isMobile"
            >
              ลงทะเบียน
            </v-btn>
          </v-row> -->

          <v-row justify="center my-2">
            <v-btn color="primary" to="/login" v-if="!isUser && isMobile">
              เข้าสู่ระบบ
            </v-btn>
          </v-row>

          <v-row justify="center my-2">
            <v-btn color="error" @click="logout" v-if="isUser && isMobile">
              ออกจากระบบ
            </v-btn>
          </v-row>
        </v-list>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app hide-on-scroll height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />

      <router-link
        to="/"
        class="d-flex primary--text"
        style="text-decoration: none"
      >
        <h3>{{ title }}</h3>
      </router-link>
      <v-autocomplete
        v-if="!isMobile"
        v-model="searchSubjectId"
        :items="searchSubjects"
        item-text="subjectName"
        item-value="subjectId"
        label="ค้นหารายวิชา"
        class="serach-input ml-5 mt-5"
        append-icon=""
        clearable
        @change="searchProduct()"
      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar>
            <v-img
              class="white--text align-end"
              :aspect-ratio="16 / 9"
              :src="imgPath(item.subjectImg, 'subject')"
            >
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.subjectName"></v-list-item-title>
            <!-- <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle> -->
          </v-list-item-content>
          <v-list-item-action>
            <v-chip class="indigo white--text font-weight-light dark">{{
              item.courseName
            }}</v-chip>
          </v-list-item-action>
        </template>
      </v-autocomplete>
      <!-- <v-text-field
        v-debounce:500="findSubject"
        v-model="searchText"
        hide-details
        label="ค้นหาคอร์สเรียน..."
        append-icon="mdi-magnify"
        single-line
        class="serach-input ml-5"
      ></v-text-field> -->
      <v-spacer />

      <template v-for="(name, menuitem) in items">
        <template v-if="name.submenu">
          <v-menu
            :key="menuitem"
            open-on-hover
            offset-y
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                class="py-8 submenubtn hidden-sm-and-down"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ name.title }}
                <v-icon right small class="mx-0"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in name.submenu"
                :key="index"
                link
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-else
          :key="menuitem"
          depressed
          tile
          plain
          class="py-8 hidden-sm-and-down"
          :to="name.to"
          >{{ name.title }}</v-btn
        >
      </template>

      <v-spacer />
      <v-btn
        class="mr-2"
        outlined
        color="primary"
        @click="toMyCourse"
        v-if="isUser && !isMobile"
      >
        คอร์สของฉัน
      </v-btn>
      <!-- <v-btn
        dark
        class="mr-2"
        color="green"
        to="/register"
        v-if="!isUser && !isMobile"
      >
        ลงทะเบียน
      </v-btn> -->
      <v-btn color="primary" to="/login" v-if="!isUser && !isMobile">
        เข้าสู่ระบบ
      </v-btn>
      <v-btn color="error" @click="logout" v-if="isUser && !isMobile">
        ออกจากระบบ
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "siteHeader",
  props: ["title"],

  data() {
    return {
      isMobile: this.$vuetify.breakpoint.xs,
      searchSubjectId: "",
      isUser: false,
      studentData: {},
      clipped: false,
      drawer: false,
      items: [
        {
          icon: "mdi-folder-home-outline",
          title: "หน้าแรก",
          to: "/",
        },
        {
          icon: "mdi-folder-image",
          title: "คอร์สเรียน",
          to: "/course",
        },
        // {
        //   icon: "mdi-folder-image",
        //   title: "บทเรียนสาธารณะ",
        //   to: "/public-course",
        // },
        {
          icon: "mdi-account",
          title: "เกี่ยวกับเรา",
          to: "/about-us",
        },
        {
          icon: "mdi-contacts",
          title: "ติดต่อเรา",
          to: "/contact",
        },
      ],
      searchSubjects: [],
    };
  },
  methods: {
    searchProduct() {
      if (this.searchSubjectId > 0) {
        this.$router.push({
          name: "Product",
          query: {
            subjectId: this.searchSubjectId,
          },
        });
      }
    },

    logout() {
      let formData = new FormData();
      formData.append("stdId", this.studentData.stdId);

      this.$http
        .post(`${process.env.VUE_APP_API_PATH}/authen/userLogout.php`, formData)
        .then((res) => {
          console.log(res);
          localStorage.setItem("studentData", "");
          localStorage.setItem("isUser", false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toMyCourse() {
      this.$router.push({
        name: "MySubject",
      });
    },

    getAllSearchSubject() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_PATH}/subject/getAllSubject.php?courseId=${this.qs}`
        )
        .then((res) => {
          this.searchSubjects = res.data;
          this.searchSubjects = this.searchSubjects.filter(
            (product) => product.subjectSta === 1
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async created() {
    await this.getAllSearchSubject();
    this.studentData = JSON.parse(localStorage.getItem("studentData"));
    this.isUser = localStorage.getItem("isUser");
    console.log("studentData", this.studentData);
  },
};
</script>

<style scoped>
.submenubtn {
  cursor: default;
}

.v-text-field {
  width: 250px;
}
</style>
