<template>
  <v-container fluid class="my-5">
    <v-row justify="center" align="center">
      <v-card class="mt-3 mx-3" max-width="1000">
        <v-card-title> ลงทะเบียน</v-card-title>
        <v-card-text>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Username"
                      rules="required"
                    >
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-account"
                        maxlength="20"
                        v-model="userForm.username"
                        :error-messages="errors"
                        label="Username"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <v-text-field
                        type="password"
                        prepend-icon="mdi-lock"
                        maxlength="20"
                        v-model="userForm.password"
                        :error-messages="errors"
                        label="Password"
                        required
                        ref="password"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <v-text-field
                        type="password"
                        prepend-icon="mdi-lock"
                        maxlength="20"
                        v-model="confirmPassword"
                        :error-messages="errors"
                        label="Confirm Password"
                        data-vv-as="Password"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row class="mb-3" align="center" justify="center">
                  <v-img
                    v-if="url"
                    :src="url"
                    max-height="300"
                    max-width="300"
                  ></v-img>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="รูปประจำตัว"
                      rules="required"
                    >
                      <v-file-input
                        :error-messages="errors"
                        @change="onFileChange"
                        accept="image/*"
                        label="รูปประจำตัว"
                        v-model="userForm.stdImg"
                      ></v-file-input>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="คำนำหน้าชื่อ"
                      rules="required"
                    >
                      <v-radio-group
                        v-model="userForm.prefix"
                        label="คำนำหน้าชื่อ"
                        :error-messages="errors"
                        row
                      >
                        <v-radio label="เด็กชาย" value="เด็กชาย"></v-radio>
                        <v-radio label="เด็กหญิง" value="เด็กหญิง"></v-radio>
                        <v-radio label="นาย" value="นาย"></v-radio>
                        <v-radio label="นาง" value="นาง"></v-radio>
                        <v-radio label="นางสาว" value="นางสาว"></v-radio>
                      </v-radio-group>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="ชื่อ"
                      rules="required"
                    >
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-account-box"
                        maxlength="20"
                        v-model="userForm.firstName"
                        :error-messages="errors"
                        label="ชื่อ"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="นามสกุล"
                      rules="required"
                    >
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-account-box-multiple"
                        maxlength="20"
                        v-model="userForm.lastName"
                        :error-messages="errors"
                        label="นามสกุล"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" lg="4" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="เบอร์โทรศัพท์"
                      rules="required"
                    >
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-phone"
                        maxlength="20"
                        v-model="userForm.tel"
                        :error-messages="errors"
                        label="เบอร์โทรศัพท์"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="4" xs="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="อีเมล"
                      rules="required|email"
                    >
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-email"
                        maxlength="100"
                        v-model="userForm.email"
                        :error-messages="errors"
                        label="อีเมล"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="4" xs="12">
                    <v-text-field
                      type="text"
                      prepend-icon="mdi-account-box-multiple"
                      maxlength="100"
                      v-model="userForm.lineId"
                      :error-messages="errors"
                      label="Line ID"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider name="รหัสประจำตัว">
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-google-classroom"
                        maxlength="20"
                        hint="รหัสประจำตัว - บัตรประชาชน"
                        persistent-hint
                        v-model="userForm.code"
                        label="รหัสประจำตัว"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" lg="6" xs="12">
                    <validation-provider name="ระดับชั้น">
                      <v-text-field
                        type="text"
                        prepend-icon="mdi-school"
                        v-model="userForm.class"
                        label="ระดับชั้น"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-btn class="mr-4" type="submit" block color="primary">
                    ยืนยัน
                  </v-btn>
                </v-col>
              </v-container>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { required, confirmed, email } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "กรุณากรอก {_field_}",
});

extend("confirmed", {
  ...confirmed,
  message: "กรุณากรอกรหัสผ่านให้ตรงกัน",
});

extend("email", {
  ...email,
  message: "กรุณากรอกอีเมลให้ถูกต้อง",
});

export default {
  name: "Register",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      url: null,
      valid: true,
      userForm: {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        code: "",
        class: "",
        prefix: "",
        lineId: "",
        tel: "",
        courseId: "",
        objective: "",
        stdImg: null,
      },
    };
  },
  methods: {
    onFileChange(e) {
      if (e) {
        this.url = URL.createObjectURL(e);
      } else {
        this.url = null;
      }
    },

    async submit() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        console.log(this.userForm);
        let formData = new FormData();

        for (const key in this.userForm) {
          formData.append(key, this.userForm[key]);
          console.log(this.userForm[key]);
        }

        this.$http
          .post(
            `${process.env.VUE_APP_API_PATH}/student/addStudent.php`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.$swal({
                icon: "success",
                title: "ลงทะเบียนสำเร็จ รอการยืนยันการผู้สอน ก่อนเข้าสู่ระบบ",
                showConfirmButton: false,
                timer: 2500,
              }).then((result) => {
                if (result) {
                  this.$router.push({ name: "Index" });
                }
              });
            }
          })
          .catch((err) => {
            this.isLoading;
            if (err) {
              this.$swal({
                icon: "error",
                title: "ชื่อ - นามสกุลนี้ถูกใช้ไปแล้ว",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      } else {
        return;
      }
    },
  },
};
</script>



