<template>
  <h1>TestIndex</h1>
</template>

<script>
export default {
  name: "TestIndex",
};
</script>

<style>
</style>