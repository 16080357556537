<template>
  <section :class="this.$vuetify.theme.dark ? '' : 'grey lighten-4'">
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
      </v-col>
    </v-row>
    <v-container class="px-5" fill-height>
      <v-row class="mt-3 mx-auto">
        <v-btn color="info" @click="$router.go(-1)" class="my-3"
          ><v-icon small class="mr-1">mdi-arrow-left</v-icon> ย้อนกลับ</v-btn
        >
        <v-col cols="8">
          <v-text-field
            v-model="search"
            clearable
            dense
            flat
            outlined
            placeholder="ค้นหา..."
            append-icon="mdi-magnify"
            class="mb-6"
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-3">
        <v-col cols="12" xl="10" lg="9" md="8" sm="8">
          <v-row>
            <v-col
              v-for="subject in subjectFilter"
              :key="subject.Id"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="4"
            >
              <v-card max-width="450" class="mx-auto" elevation="1">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="imgPath(subject.subjectImg, 'subject')"
                >
                </v-img>
                <v-card-subtitle class="pb-0">
                  <v-row cols="12">
                    <v-col cols="4">
                      <span
                        v-if="subject.subjectProgress === 100"
                        class="green--text"
                        >ผ่านการทดสอบ</span
                      >
                      <span class="amber--text" v-else>กำลังศึกษา</span>
                    </v-col>
                    <v-col>
                      <v-progress-linear
                        :color="
                          subject.subjectProgress === 100 ? 'green' : 'amber'
                        "
                        height="20"
                        :value="subject.subjectProgress"
                        striped
                      >
                        <template v-slot:default="{ value }">
                          <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-subtitle class="pb-0">
                  <span>
                    ระยะเวลาเรียนตั้งแต่
                    {{ subject.startDate | moment("dddd, Do MMMM YYYY") }}</span
                  >
                  ถึง
                  {{ subject.endDate | moment("dddd, Do MMMM YYYY") }}
                </v-card-subtitle>

                <v-card-text
                  class="title font-weight-bold mt-3 pb-0 text--primary"
                  style="line-height: 1.8rem"
                >
                  {{ subject.subjectName }}
                </v-card-text>

                <v-card-actions>
                  <v-chip class="info" small>
                    <span
                      >ระยะเวลาคงเหลือ
                      {{ subject.endDate | moment("from", "now") }}
                    </span>
                  </v-chip>

                  <v-btn
                    class="ml-auto"
                    color="primary"
                    right
                    @click="toLesson(subject)"
                    >เลือกเรียน</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <div class="text-center">
            <div class="text-center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-container class="max-width">
                      <v-pagination
                        v-model="page"
                        circle
                        class="my-4"
                        :length="1"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="4">
          <aside>
            <v-card outlined class="mb-6">
              <div
                class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                คอร์สเรียน
              </div>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="subject in subjects"
                    :key="subject.subectId"
                    @click="toLesson(subject)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="subject.subjectName">
                      </v-list-item-title>

                      <v-list-item-subtitle
                        v-text="subjectAmount(subject.lessonAmount)"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip
                        dark
                        small
                        :color="
                          subject.subjectProgress === 100 ? 'green' : 'amber'
                        "
                      >
                        {{
                          subject.subjectProgress === 100
                            ? "ผ่านการทดสอบ"
                            : "กำลังศึกษา"
                        }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </aside>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import "@/mixins/generalMixin";
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "MySubject",
  components: {
    HeroAlt,
  },
  data() {
    return {
      page: 1,
      pageSize: 8,
      listCount: 0,
      historyList: [],
      search: "",
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "คอร์สเรียนของฉัน",
        },
      ],
      subjects: [],
      posts: [
        {
          id: "",
          title: "Hello World",
          postImage: "",
          publishedOn: "",
          lastUpdated: "",
          author: "",
          tags: "",
          category: "",
          excerpt: "",
          content: "",
        },
      ],
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },

    subjectFilter() {
      return this.subjects.filter((subject) => {
        return subject.subjectName.toLowerCase().match(this.search);
      });
    },
  },
  methods: {
    initPage() {
      let _this = this;
      _this.listCount = _this.products.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.products;
      } else {
        _this.historyList = _this.products.slice(0, _this.pageSize);
      }
    },
    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.products.slice(_start, _end);
      _this.page = pageIndex;
    },
    subjectAmount(lessonAmount) {
      return `จำนวนบทเรียน ${lessonAmount}`;
    },
    toLesson(subject) {
      localStorage.setItem("subjectId", subject.subjectId);
      this.$router.push({
        name: "MyLesson",
        query: {
          subjectImg: subject.subjectImg,
        },
      });
    },
    getMySubject() {
      let formData = new FormData();
      formData.append("stdId", localStorage.getItem("stdId"));

      this.$http
        .post(
          `${process.env.VUE_APP_API_PATH}/subject/getAllStdSubject.php`,
          formData
        )
        .then((res) => {
          this.subjects = res.data;
          this.heroAlt[0].heading = "MyCourse";

          console.log("res", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStdSubject() {
      let formData = new FormData();
      formData.append("stdId", localStorage.getItem("stdId"));

      this.$http
        .post(
          `${process.env.VUE_APP_API_PATH}/subject/getStdSubject.php`,
          formData
        )
        .then((res) => {
          this.subjects = res.data.allStd[0];
          res.data.stdSta[0].map((data, index) => {
            this.subjects[index].subjectProgress = data.subjectProgress;
            this.subjects[index].finishDate = data.finishDate;
          });

          this.subjects = this.subjects.filter(
            (subject) => subject.isExpire !== 1
          );

          this.subjects = this.subjects.filter(
            (subject) => subject.active !== 0
          );

          console.log("getStdSubject", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  async created() {
    await this.getStdSubject();

    setInterval(() => {
      this.getStdSubject();
    }, 5000);
  },
};
</script>
