<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
      </v-col>
    </v-row>
    <section :class="this.$vuetify.theme.dark ? '' : 'grey lighten-4 pa-3'">
      <v-card
        :loading="isLoading"
        class="mx-auto my-12"
        max-width="1000"
        v-if="isError"
      >
        <v-card-title> ไม่พบแบบทดสอบในบทเรียนนี้ </v-card-title>
      </v-card>
      <v-btn color="info" @click="$router.go(-1)" class="my-3"
        ><v-icon small class="mr-1">mdi-arrow-left</v-icon> ย้อนกลับ</v-btn
      >
      <v-card
        :loading="isLoading"
        class="mx-auto my-12"
        max-width="1000"
        v-if="!isError && !showHistory"
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-divider class="mx-4"></v-divider>
        <v-row
          :class="`ma-2 ${quiz.class}`"
          v-for="(quiz, index) in quizs"
          :key="quiz.quizId"
        >
          <v-card-title v-if="quiz.question2">
            <v-row>
              <v-col cols="12">
                ข้อที่.{{ index + 1 }}
                {{ quiz.question }}
              </v-col>
              <vuetify-audio
                class="audio"
                flat
                :file="filePath(quiz.question2, 'quiz')"
                color="primary"
              ></vuetify-audio>
            </v-row>
          </v-card-title>

          <v-card-title v-else>
            ข้อที่.{{ index + 1 }} {{ quiz.question }}
          </v-card-title>

          <v-spacer></v-spacer>
          <v-icon class="mr-3" :color="`${quiz.color}`" large>{{
            quiz.check
          }}</v-icon>

          <v-card-text
            class="pa-0 pl-3"
            v-if="quiz.quizType === 'choice' || quiz.quizType === 'sound'"
          >
            <v-row class="ma-3">
              <v-img
                max-height="500"
                max-width="500"
                v-if="quiz.questionImg"
                :src="imgPath(quiz.questionImg, 'quiz')"
              ></v-img>
            </v-row>

            <v-radio-group :disabled="isDisabled">
              <v-radio
                v-if="!quiz.a1"
                :label="quiz.a"
                value="a"
                @click="chooseChoice(quiz.a, quiz.quizId)"
              >
              </v-radio>

              <v-radio
                class="ma-2"
                v-if="quiz.a1"
                :value="quiz.a1"
                @click="chooseChoice(quiz.a1, quiz.quizId)"
              >
                <template slot="label">
                  <v-img
                    max-height="200"
                    max-width="200"
                    v-if="quiz.a1"
                    :src="imgPath(quiz.a1, 'quiz')"
                  ></v-img>
                </template>
              </v-radio>

              <v-radio
                v-if="!quiz.b1"
                :label="quiz.b"
                value="b"
                @click="chooseChoice(quiz.b, quiz.quizId)"
              ></v-radio>

              <v-radio
                class="ma-2"
                v-if="quiz.b1"
                :value="quiz.b1"
                @click="chooseChoice(quiz.b1, quiz.quizId)"
              >
                <template slot="label">
                  <v-img
                    max-height="200"
                    max-width="200"
                    v-if="quiz.b1"
                    :src="imgPath(quiz.b1, 'quiz')"
                  ></v-img>
                </template>
              </v-radio>

              <v-radio
                v-if="!quiz.c1"
                :label="quiz.c"
                value="c"
                @click="chooseChoice(quiz.c, quiz.quizId)"
              ></v-radio>

              <v-radio
                class="ma-2"
                v-if="quiz.c1"
                :value="quiz.c1"
                @click="chooseChoice(quiz.c1, quiz.quizId)"
              >
                <template slot="label">
                  <v-img
                    max-height="200"
                    max-width="200"
                    v-if="quiz.c1"
                    :src="imgPath(quiz.c1, 'quiz')"
                  ></v-img>
                </template>
              </v-radio>

              <v-radio
                v-if="!quiz.d1"
                :label="quiz.d"
                value="d"
                @click="chooseChoice(quiz.d, quiz.quizId)"
              ></v-radio>

              <v-radio
                class="ma-2"
                v-if="quiz.d1"
                :value="quiz.d1"
                @click="chooseChoice(quiz.d1, quiz.quizId)"
              >
                <template slot="label">
                  <v-img
                    max-height="200"
                    max-width="200"
                    v-if="quiz.d1"
                    :src="imgPath(quiz.d1, 'quiz')"
                  ></v-img>
                </template>
              </v-radio>
            </v-radio-group>

            <!-- <v-chip-group active-class="primary accent-4 white--text" column>
              <v-chip @click="chooseChoice('a', quiz.quizId)"
                >ก. {{ quiz.a }}</v-chip
              >

              <v-chip @click="chooseChoice('b', quiz.quizId)"
                >ข. {{ quiz.b }}</v-chip
              >

              <v-chip @click="chooseChoice('c', quiz.quizId)"
                >ค. {{ quiz.c }}</v-chip
              >

              <v-chip @click="chooseChoice('d', quiz.quizId)"
                >ง. {{ quiz.d }}</v-chip
              > -->
            <!-- </v-chip-group> -->
            <v-divider class="mt-3"></v-divider>
          </v-card-text>

          <v-card-text class="pa-0 pl-3" v-if="quiz.quizType === 'text'">
            <v-card elevation="7" class="ma-3">
              <v-card-title
                >{{ quiz.a }}
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      class="mx-2 mt-3 ml-4"
                      :disabled="isDisabled"
                      @keyup="addTextAnswer($event, index)"
                      type="text"
                      dense
                      maxlength="100"
                      label="คำตอบ"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-card-text>
        </v-row>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            class="ml-auto"
            @click="quizSummary(confirmButton)"
          >
            {{ confirmButton }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="!isError && showHistory"
        :loading="isLoading"
        class="mx-auto my-12"
        max-width="1000"
      >
        <v-card-title> ประวัติการทำแบบทดสอบ {{ lessonName }} </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="history"
            :items-per-page="10"
            class="elevation-1"
          >
            <template slot="item.index" scope="props">
              {{ props.index + 1 }}
            </template>

            <template v-slot:item.makeDate="{ item }">
              {{
                toThaiDateString(
                  new Date(item.makeDate.replace(/\s/, "T") + "Z")
                )
              }}
            </template>

            <template v-slot:item.isPass="{ item }">
              {{ testStatusSumary(item.stdScore, item.maxScore) }}
            </template>

            <template v-slot:item.isPass="{ item }">
              {{ testStatusSumary(item.stdScore, item.maxScore) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn class="ml-auto" color="success" @click="showHistory = false">
            ทำแบบทดสอบ
          </v-btn>
        </v-card-actions>
      </v-card>
    </section>
  </div>
</template>
<script>
import "@/mixins/generalMixin";
import HeroAlt from "@/components/Sections/HeroAlt";

export default {
  name: "DoTest",
  components: {
    HeroAlt,
  },
  data() {
    return {
      headers: [
        {
          text: "ลำดับที่",
          value: "index",
          sortable: false,
          class: "light-blue darken-3 white--text",
        },
        {
          text: "วันที่ทำข้อสอบ",
          value: "makeDate",
          class: "light-blue darken-3 white--text",
        },
        {
          text: "คะแนนที่ได้",
          value: "stdScore",
          class: "light-blue darken-3 white--text",
        },
        {
          text: "คะแนนเต็ม",
          value: "maxScore",
          class: "light-blue darken-3 white--text",
        },
        {
          text: "สถานะ",
          value: "isPass",
          class: "light-blue darken-3 white--text",
        },
      ],
      history: [],
      showHistory: true,
      isError: false,
      isPass: false,
      isDisabled: false,
      confirmButton: "ยืนยัน",
      isLoading: false,
      quizs: [],
      choiceList: [],
      score: 0,
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "แบบทดสอบ",
        },
      ],
      stdId: null,
      setting: 0,
    };
  },
  methods: {
    testStatusSumary(score, maxScore) {
      if (score >= Math.ceil(maxScore / 2)) {
        return "ผ่าน";
      } else {
        return "ไม่ผ่าน";
      }
    },

    addTextAnswer(e, index) {
      this.choiceList[index].answer = e.target.value;
    },

    chooseChoice(choose, quizId) {
      this.choiceList.map((choice) => {
        if (
          choice.answer !== null &&
          choice.quizId === quizId &&
          choice.answer === choose
        ) {
          choice.answer = null;
          return;
        }

        if (choice.quizId === quizId) {
          choice.answer = choose;
        }
      });
    },

    async getTestScore() {
      this.subjectId = localStorage.getItem("subjectId");
      const jsonData = JSON.stringify({
        subjectId: localStorage.getItem("subjectId"),
        stdId: this.stdId,
      });

      const data = this.post("/quiz/getTestScore.php", jsonData);
      data
        .then((res) => {
          console.log("getTestScore", res.data);
          this.history = res.data.filter((data) => data.scoreType === "test");
        })
        .catch((err) => {
          if (err) {
            this.showHistory = false;
          }
        });
    },

    getTest() {
      this.subjectId = localStorage.getItem("subjectId");
      const jsonData = JSON.stringify({
        subjectId: localStorage.getItem("subjectId"),
      });

      const data = this.post("/quiz/getTest.php", jsonData);
      data
        .then((res) => {
          this.quizs = res.data;
          this.quizs = this.quizs.filter((quiz) => quiz.isExam === 1);
          // this.quizs.sort(() => Math.random() - 0.5);

          this.choiceList = this.quizs.map((quiz) => {
            console.log("answer", quiz.answer);
            return {
              quizId: quiz.quizId,
              answer: null,
              result: quiz.answer,
              questionImg: quiz.questionImg,
            };
          });

          console.log("quizs", this.quizs);
          console.log("choiceList", this.choiceList);
        })
        .catch((err) => {
          if (err) {
            this.isError = true;
          }
        });
    },

    quizSummary(confirmButtonText) {
      if (confirmButtonText !== "ยืนยัน") {
        this.$router.go(-1);
        return;
      }
      this.isLoading = true;
      this.score = 0;
      let standard = Math.ceil((this.setting * this.choiceList.length) / 100);

      if (standard === 0) standard = 1;
      let isNullAnswer = false;

      this.choiceList.map((choice) => {
        if (choice.answer === null) {
          isNullAnswer = true;
        }
      });

      if (isNullAnswer) {
        this.isLoading = false;
        this.$swal.fire({
          icon: "warning",
          title: "กรุณาเลือกคำตอบให้ครบ",
          allowOutsideClick: false,
        });
        return;
      } else {
        this.isDisabled = true;
        this.confirmButton = "ย้อนกลับ";

        this.choiceList.map((choice, index) => {
          console.log(index, choice.answer, choice.result);
          if (choice.answer === choice.result) {
            this.score++;
            console.log("quizs2", this.quizs[index]);
            this.quizs[index].class = "success-border";
            this.quizs[index].check = "mdi-check-bold";
            this.quizs[index].color = "green";
          } else {
            console.log("quizs2", this.quizs[index]);
            this.quizs[index].class = "error-border";
            this.quizs[index].check = "mdi-close-thick";
            this.quizs[index].color = "red";
          }
        });
        this.isLoading = false;
        this.score >= standard ? (this.isPass = 1) : (this.isPass = 0);
        this.saveScore();
        this.updateProgress();
        this.$swal.fire({
          icon: `${this.score >= standard ? "success" : "error"}`,
          text: `เกณฑ์การผ่านแบบทดสอบ ${standard} คะแนน`,
          title: `ท่านได้คะแนน ${this.score} / ${
            this.choiceList.length
          } ${this.summaryText(standard, this.score)}`,
          confirmButtonText: `ตกลง`,
          allowOutsideClick: false,
        });
      }
    },

    updateProgress() {
      //   let formData = new FormData();
      //   formData.append("stdId", this.$route.query.stdId);
      //   this.$http
      //     .post(
      //       `${process.env.VUE_APP_API_PATH}/quiz/getStdProgress.php`,
      //       formData
      //     )
      //     .then((res) => {
      //       let count = 0;
      //       this.subjects.map((subject) => {
      //         res.data.map((data, index) => {
      //           if (subject.subjectId === data.subjectId) {
      //             subject.totalLesson = index + 1;
      //             if (data.isDone === 1) {
      //               count++;
      //               subject.lessonDone = count;
      //             }
      //           }
      //         });
      //       });
      //       this.subjects.map((subject) => {
      //         let p = Math.floor(
      //           (subject.lessonDone / subject.totalLesson) * 100
      //         );
      //         subject.subjectProgress = p;
      //       });
      //       console.log("subjects", this.subjects);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // },
    },

    saveScore() {
      let formData = new FormData();
      formData.append("stdScore", this.score);
      formData.append("maxScore", this.choiceList.length);
      formData.append("scoreType", "test");
      formData.append("isPass", this.isPass);
      formData.append("stdId", this.stdId);
      formData.append("lessonId", localStorage.getItem("lessonId"));
      formData.append("subjectId", localStorage.getItem("subjectId"));

      this.$http
        .post(`${process.env.VUE_APP_API_PATH}/quiz/addTestScore.php`, formData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sumConfirmText(standard, score) {
      if (score >= standard) {
        return "ตกลง";
      } else {
        return "ทำแบบทดสอบใหม่อีกครั้ง";
      }
    },

    summaryText(standard, score) {
      if (score >= standard) {
        return "คุณผ่านแบบทดสอบ";
      } else {
        return "ยังไม่ผ่านแบบทดสอบกรุณาพยายามใหม่อีกครั้ง";
      }
    },

    async getPercent() {
      const res = await this.$http.get(
        `${process.env.VUE_APP_API_PATH}/quiz/getQuizSetting.php`
      );
      this.setting = res.data[0].quizSetting;
    },
  },
  created() {
    this.stdId = localStorage.getItem("stdId");
    this.getTest();
    this.getPercent();
    this.getTestScore();
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid #f44336;
  border-radius: 5px;
}

.success-border {
  border: 2px solid #4caf50;
  border-radius: 5px;
}
</style>
