import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "leaflet/dist/leaflet.css";
import "nprogress/nprogress.css";
import vueDebounce from "vue-debounce";

const moment = require("moment");
require("moment/locale/th");

Vue.use(vueDebounce);
Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(VueVideoPlayer);
Vue.prototype.$http = Axios;
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
