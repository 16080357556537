<template>
  <div>
    <section id="home">
      <v-row no-gutters>
        <v-col cols="12">
          <hero></hero>
          <v-row justify="center" class="mt-7">
            <h1 class="display-2 font-weight-black primary--text">
              คอร์สเรียน
            </h1>
          </v-row>
          <div v-for="(item, i) in products" :key="i">
            <LessonCard :items="item" />
          </div>
          <!-- <news-section></news-section> -->
          <!-- <course-section></course-section> -->
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import "@/mixins/generalMixin";
import LessonCard from "@/components/LessonCard/LessonCard";
import Hero from "@/components/Sections/Hero";
import _ from "lodash";

export default {
  name: "Index",
  components: {
    LessonCard,
    Hero,
  },
  data() {
    return {
      products: [],
      colors: [
        "#536dfe",
        "#F44336",
        "#009688",
        "#3F51B5",
        "#2196F3",
        "#FF9800",
        "#4CAF50",
        "#FFC107",
        "#4CAF50",
        "#673AB7",
      ],
    };
  },
  methods: {
    getAllCourse() {
      this.$http
        .get(`${process.env.VUE_APP_API_PATH}/subject/getAllSubject.php`)
        .then((res) => {
          this.products = res.data;
          this.products = this.products.filter(
            (product) => product.subjectSta === 1
          );

          let i = 0;
          this.products = _.groupBy(this.products, "courseId");

          Object.entries(this.products).forEach(([key, value], index) => {
            _.each(value, (element) => {
              element.color = this.colors[index];
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getAllCourse();
  },
};
</script>

<style scoped>
</style>
