<template>
  <v-container class="my-3 px-5">
    <v-row class="my-2" justify="center">
      <v-col>
        <v-btn color="info" @click="$router.go(-1)" class="my-3"
          ><v-icon small class="mr-1">mdi-arrow-left</v-icon> ย้อนกลับ</v-btn
        >
        <h1>เนื้อหาบทเรียน</h1>
        <v-card
          elavation="24"
          class="pa-3"
          v-html="lessons[0].content"
        >
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5" v-if="filesData">
      <h5 class="mt-3">ไฟล์ประกอบการสอน</h5>
      <v-col cols="12">
        <v-btn
          @click="viewFiles(file, index)"
          color="info"
          class="ml-3 mt-2"
          v-for="(file, index) in filesData"
          :key="file.fileId"
          >{{ file.name }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="my-5" v-if="lessonYoutube">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Youtube Video</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              class="ma-3"
              v-for="item in lessonYoutube"
              :key="item.youtube_id"
            >
              <iframe
                width="450"
                height="500"
                :src="`https://www.youtube.com/embed/${item.youtube_id}`"
              >
              </iframe>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-card class="mx-auto p-10 mt-4" v-if="videoData">
      <h1>วิดีโอประกอบการสอน</h1>
      <div class="item">
        <div class="player">
          <video-player
            oncontextmenu="return false"
            class="vjs-custom-skin"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)"
            @waiting="onPlayerWaiting($event)"
            @playing="onPlayerPlaying($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          >
          </video-player>
        </div>
      </div>
    </v-card>
    <v-row class="my-3">
      <v-btn
        @click="changeVideo(lesson, index)"
        class="ma-1"
        :color="active === index ? 'primary' : ''"
        v-for="(lesson, index) in videoData"
        :key="lesson.lessonId"
        >วิดีโอที่ {{ index + 1 }}</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import "@/mixins/generalMixin";
export default {
  name: "ViewCourse",
  data() {
    return {
      active: 0,
      lessons: [],
      filesData: [],
      lessonYoutube: [],
      playerOptions: {
        // videojs options
        height: 500,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "http://lms.appdii.com/videos/test.m4v",
          },
        ],
        poster: "",
      },
    };
  },
  mounted() {
    console.log("this is current player instance object", this.player);
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    openYoutube() {
      window.open(this.lessonYoutube, "_blank");
    },

    viewFiles(file) {
      window.open(this.filePath(file.name, "files"), "_blank");
    },

    onPlayerTimeupdate(player) {
      console.log("time", player);
    },

    onPlayerPlaying(player) {
      console.log("playing", player);
    },

    changeVideo(lesson, index) {
      this.active = index;
      console.log("changeVideo", this.videoPath(lesson.videoName, "video"));
      if (lesson.videoName) {
        this.playerOptions.sources[0].src = this.videoPath(
          lesson.videoName,
          "video"
        );
      }
    },
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },

    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    getLesson() {
      let formData = new FormData();
      formData.append("lessonId", this.$route.query.lessonId);

      this.$http
        .post(`${process.env.VUE_APP_API_PATH}/lesson/getLesson.php`, formData)
        .then((res) => {
          console.log('res.data', res.data);

          this.lessons = res.data.lessonData;
          this.lessonYoutube = res.data.lessonYoutube;
          this.videoData = res.data.videoData;

          this.filesData = res.data.filesData;

          if (res.data.videoData.length > 0) {
            this.playerOptions.sources[0].src = this.videoPath(
              res.data.videoData[0].videoName,
              "video"
            );
          }

          if (res.data.lessonData.length >= 0) {
            this.playerOptions.poster = this.imgPath(
              res.data.lessonData[0].lessonImage,
              "lesson"
            );
          }

          console.log("lesson", this.lessons);
          console.log("filesData", this.filesData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getLesson();
  },
};
</script>

<style>
@import "./custom-theme.css";
</style>
