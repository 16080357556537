<template>
  <section :class="this.$vuetify.theme.dark ? '' : 'grey lighten-4'">
    <v-row no-gutters>
      <v-col cols="12">
        <hero-alt :hero-alt="heroAlt"></hero-alt>
      </v-col>
    </v-row>
    <v-container class="px-5" fill-height>
      <v-row class="mt-3 mx-auto">
        <v-btn color="info" @click="$router.go(-1)" class="my-3"
        ><v-icon small class="mr-1">mdi-arrow-left</v-icon> ย้อนกลับ</v-btn
        >
        <v-col cols="8">
          <v-text-field
              v-model="search"
              clearable
              dense
              flat
              outlined
              placeholder="ค้นหา..."
              append-icon="mdi-magnify"
              class="mb-6"
              hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="12" xl="10" lg="9" md="8" sm="8">
          <v-row>
            <v-col
                v-for="subject in subjectFilter"
                :key="subject.Id"
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="4"
            >
              <v-card max-width="450" class="mx-auto" elevation="1">
                <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="imgPath(subject.subjectImg, 'subject')"
                >
                </v-img>

                <v-card-text
                    class="title font-weight-bold mt-3 pb-0 text--primary"
                    style="line-height: 1.8rem"
                >
                  {{ subject.subjectName }}
                </v-card-text>

                <v-card-actions>
                  <v-btn
                      class="ml-auto"
                      color="primary"
                      right
                      @click="toLesson(subject)"
                  >เลือกเรียน</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <div class="text-center">
            <div class="text-center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-container class="max-width">
                      <v-pagination
                          v-model="page"
                          circle
                          class="my-4"
                          :length="1"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="4">
          <aside>
            <v-card outlined class="mb-6">
              <div
                  class="
                  subtitle
                  font-weight-black
                  text-uppercase text-center
                  mt-4
                "
              >
                คอร์สเรียน
              </div>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item
                      v-for="subject in subjects"
                      :key="subject.subectId"
                      @click="toLesson(subject)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="subject.subjectName">
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </aside>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import HeroAlt from "@/components/Sections/HeroAlt.vue";

export default {
  name: "PublicCourse",
  components: {
    HeroAlt,
  },
  data() {
    return {
      page: 1,
      pageSize: 8,
      listCount: 0,
      search: "",
      subjects: [],
      heroAlt: [
        {
          src: localStorage.getItem("slideImg"),
          heading: "คอร์สเรียนสาธารณะ",
        },
      ],   
    }
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },

    subjectFilter() {
      return this.subjects.filter((subject) => {
        return subject.subjectName.toLowerCase().match(this.search);
      });
    },
  },
  methods: {
    toLesson(subject) {
      localStorage.setItem("subjectId", subject.subjectId);
      this.$router.push({
        name: "PublicMyLesson",
        query: {
          subjectImg: subject.subjectImg,
        },
      });
    },
    getAllSubject() {
      this.$http
        .get(
            `${process.env.VUE_APP_API_PATH}/subject/getAllSubject.php`
        )
        .then((res) => {
          this.subjects = res.data;

          this.subjects = this.subjects.filter(
              (subject) => subject.isExpire !== 1
          );

          this.subjects = this.subjects.filter(
              (subject) => subject.publicSta !== 0
          );

          console.log("getAllSubject", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 
  },
  async mounted() {
    await this.getAllSubject();
  }
}
</script>