<template>
  <v-container fluid>
    <v-layout align-start justify-center>
      <v-flex sm12 md9 lg12 class="pa-5">
        <v-row cols="12">
          <v-col cols="12">
            <p class="display-1 font-weight-light text-center pa-4 pb-0">
              {{ newsForm.newsTitle }}
            </p>
            <p class="overline text-center">
              {{ newsForm.created_at }}
            </p>
            <v-container fluid>
              <v-row justify="center">
                <v-img
                  max-width="500"
                  max-height="500"
                  :src="imgPath(newsForm.newsImg, 'news')"
                ></v-img>
                <v-col
                  class="content"
                  md="12"
                  lg="12"
                  v-html="newsForm.newsDesc"
                >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import "@/mixins/generalMixin";

export default {
  data() {
    return {
      newsForm: [],
    };
  },
  methods: {
    toThaiDateString(date) {
      let monthNames = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม.",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      let year = date.getFullYear() + 543;
      let month = monthNames[date.getMonth()];
      let numOfDay = date.getDate();

      let hour = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let second = date.getSeconds().toString().padStart(2, "0");

      return (
        `${numOfDay} ${month} ${year} ` + `${hour}:${minutes}:${second} น.`
      );
    },

    getNews() {
      const jsonData = JSON.stringify({ id: this.$route.query.id });

      this.$http
        .post(`${process.env.VUE_APP_API_PATH}/news/getNews.php`, jsonData)
        .then((res) => {
          this.newsForm = res.data[0];
          let dateTime = new Date(res.data[0].created_at);
          this.newsForm.created_at = this.toThaiDateString(dateTime);
          this.isLoading = false;

          console.log("====================================");
          console.log("news", this.newsForm);
          console.log("====================================");
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.getNews();
  },
};
</script>
<style>
img .img-responsive {
  max-width: 100%;
  height: auto;
}

.img-responsive {
  display: block;
  max-width: 100% !important;
  height: auto;
}

table {
  max-width: 100% !important;
  overflow-x: auto !important;
}
</style>
