<template>
  <div class="px-10 py-4">
    <h2 class="text-h5 pb-4 grey--text text--darken-3">
      {{ items[0].courseName }}
      <v-icon :color="colors[0]">mdi-circle</v-icon>
    </h2>

    <v-row>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="12"
        lg="3"
        md="6"
      >
        <v-hover close-delay="50" open-delay="50">
          <div>
            <v-card flat hover :to="`product?subjectId=${item.subjectId}`">
              <v-img
                :aspect-ratio="16 / 9"
                class="elevation-2"
                gradient="to top, rgba(25,32,72,.4), rgba(25,32,72,.0)"
                height="200px"
                :src="imgPath(item.subjectImg, 'subject')"
                style="border-radius: 16px"
              >
                <!-- <v-card-text>
                  <v-btn color="accent" to="category">TIPS</v-btn>
                </v-card-text> -->
              </v-img>

              <v-card-text>
                <div class="text-h5 font-weight-bold primary--text">
                  {{ item.subjectName }}
                </div>

                <!-- <div
                  class="text-body-1 py-4 description"
                  v-html="item.subjectDesc"
                ></div> -->

                <div class="d-flex align-center mt-3">
                  <v-avatar :color="item.color" size="36">
                    <v-icon dark>mdi-book</v-icon>
                  </v-avatar>

                  <div class="pl-1">
                    {{ totalLesson(item) }}
                  </div>
                </div>

                <div class="d-flex align-center mt-3" style="gap: 5px">
                  <v-avatar
                    :color="item.publicSta === 1 ? 'green' : 'orange'"
                    size="36"
                  >
                    <v-icon dark>mdi-certificate</v-icon>
                  </v-avatar>

                  <div>
                    {{ item.publicSta === 1 ? "คอร์สฟรี" : "คอร์สลงทะเบียน" }}
                  </div>
                </div>

                <v-row justify="end" class="ma-1">
                  <div class="mr-2">ยอดเข้าชม {{ item.viewCount }} |</div>
                  <div>ยอดเข้าเรียน {{ item.registerCount }}</div>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-hover>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
import "@/mixins/generalMixin";
import _ from "lodash";

export default {
  name: "LessonCard",
  props: {
    items: Array,
  },
  data() {
    return {
      colors: [],
    };
  },
  methods: {
    totalLesson(item) {
      console.log("🚀 ~ totalLesson ~ item:", item);
      return `จำนวนบทเรียน ${item.lessonAmount} (${item.totalHour} ชั่วโมง)`;
    },
  },
  created() {
    _.each(this.items, (element) => {
      this.colors.push(element.color);
    });
  },
};
</script>

<style lang="css" scoped>
.description {
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
